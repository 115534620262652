<template>
  <transition name="fade">
  <ion-page>
    <toolbar></toolbar>
    <ion-content fullscreen> 
      <ion-grid>
        <ion-row class="ion-justify-content-around">
        <ion-col  size-md="8">
          <div class="card-title ion-padding-start">Create New Ad</div>
          <form class="ion-padding">
             <ion-row>
                <ion-col>
                 <div class="radio-group">
                   <div class="radio-item" v-for="option in options.type" :key="option.val">
                     <input 
                        type="radio" 
                        :id="`option-${option.val}`" 
                        v-model="state.type" 
                        :value="option.val" 
                        :disabled="!option.active"
                        name="adtype">
                     <label :for="`option-${option.val}`">{{ option.desc }}</label>
                   </div>
                </div>
                 <div class="radio-group">
                   <div class="radio-item" v-for="option in options.category" :key="option.val">
                     <input 
                        type="radio" 
                        :id="`option-${option.val}`" 
                        v-model="state.category" 
                        :value="option.val" 
                        :disabled="!option.active"
                        name="adCategory">
                     <label :for="`option-${option.val}`">{{ option.desc }}</label>
                   </div>
                </div>
                <ion-item lines="none">
                  <ion-label position="floating">Title</ion-label>
                  <ion-input 
                    v-model="state.title" 
                    color="step-650" 
                    required="true"
                    maxlength="50"
                    ></ion-input>
                </ion-item>     
                <ion-item lines="none">
                  <ion-label position="floating">Price</ion-label>
                  <ion-input v-model="state.price" type="number" 
                  inputmode="decimal" min="0" max="99999"
                  required="true"></ion-input>
                </ion-item>  
                <ion-item lines="none">
                  <ion-label position="floating">Description</ion-label>
                  <ion-textarea v-model="state.description" rows="7"></ion-textarea>
                </ion-item>  
                <input type="file" @change="onFileSelected" v-show="false" ref="fileRef"/>
                <div class="imglist">
                  <span>Photos</span>
                  <ion-row> 
                   <ion-col v-for="(img, index) in state.images" :key="index" size-xs="4" size-sm="3">
                    <img :src="readFileURL(img)"/>
                    <ion-icon class="remove-img" :icon="closeOutline"  @click.prevent="removeImage(index)"></ion-icon>
                  </ion-col>
                  <ion-icon :icon="addOutline" size-xs="4" size-sm="3" class="add-icon" @click="fileUpload"></ion-icon>
                  </ion-row>
                </div>
                <ion-button expand="block" class="submit-ad" id="fileImage" @click="submitAd">Submit</ion-button>
                <div>Note: All listings will automatically expire after 30 days</div>
                </ion-col>
             </ion-row>
            </form>
          </ion-col>
        </ion-row>
      </ion-grid>     
    </ion-content>
  </ion-page>
  </transition>
</template>

<script>
import { IonPage, IonContent,
         IonGrid, IonRow, IonCol,
        IonItem, 
        IonLabel, 
        IonIcon,
        // IonList, 
        // IonListHeader,
        IonInput, 
        IonTextarea,
        // IonRadio, 
        // IonRadioGroup,
        // IonButtons,
        IonButton,
        toastController
       } from '@ionic/vue'
import { defineComponent, reactive, computed, 
        watch, ref, inject, onMounted ,
        onUpdated, nextTick } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import { addOutline, closeOutline } from 'ionicons/icons'
import axios from '@/utils/api'
import { useRouter, useRoute } from 'vue-router'
// import firebase from 'firebase/app'
// import 'firebase/storage'

export default defineComponent({
  name: 'CreatePostFirst',
  components: { 
        IonPage, IonContent,
        IonGrid, IonRow, IonCol,
        Toolbar,
        IonItem, 
        IonLabel, 
        IonIcon,
        // IonList, 
        // IonListHeader,
        IonInput, 
        IonTextarea,
        // IonRadio, 
        // IonRadioGroup,
        // IonButtons,
        IonButton,
        // toastController
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const fileRef = ref(null)
    const loadSpin = inject('loadSpinner')
    const state = reactive({
      type: "rent",
      title: "",
      price: 0.00,
      category: "property",
      images: [],
    })
    let submitFlag = false
    
    const cats = [
        { val: "property", desc: "Property", active: true, icon: "", types: ["rent", "sell"]},
        { val: "handyman", desc: "Handyman", active: true, icon: "", types: ["hire"]},
        { val: "lorry", desc: "Moving", active: true, icon: "", types: ["hire"]},
      ]
    
    const options = reactive({
      type: [
        { val: "rent", desc: "Rent", active: true,
        },
        { val: "hire", desc: "Hire", active: true},
        { val: "sell", desc: "Sell", active: false},
      ],
      category: computed(() => {
          return cats.filter(x => x.types.find(y => y === state.type))
      })
    })
    function fileUpload () {
      fileRef.value.click()
    }

    async function openToast(msg) {
      const toast = await toastController
        .create({
          message: msg,
          duration: 2000
        })
      return toast.present();
    }

    function submitAd () {
      const fdata = new FormData
      
       
      if (state.images.length > 0) {
        for (let i = 0; i < state.images.length; i++) {
          const image = state.images[i]
           fdata.append('images', image)
          // console.log(attachment)
        }
      } else {
        openToast('At least one photo is required.')
        return
      }

      loadSpin.status = true
      const payload = {
          type: state.type,
          title: state.title,
          price: state.price,
          category: { 
            code: state.category
          },
          description: state.description
      }
      // console.log(payload)

      // for (let i = 0; i < state.images.length; i++) {
      //   const image = state.images[i]
      //   const storageRef = firebase.storage().ref(image.name)
      //   storageRef.put(image).then(snapshot => {
      //     console.log('Uploaded a blob or file!')
      //     console.log(snapshot)
      //   })
      // }
      fdata.append('data', JSON.stringify(payload))
      console.log('submitting form data')
      const api = `/${route.params.countrycode}/p/`

      axios.post(api, fdata)
      .then(async (res) => {
         // console.log(res.result)
        const post = res.data

        // console.log('inside post')
        await router.push({ 
          name: 'createComplete', 
          params: {
            id: post.id,
            // category: route.params.category,
            countrycode: 'sg', //route.params.countrycode,
            item: JSON.stringify(post)
          }
        })
        // console.log('after push')
        // console.log(post)
        submitFlag = true
        state.type = "rent"
        state.title = ""
        state.price = 0.00
        state.category = "property"
        state.description = ""
        state.images = []
        // the spin stop in the second page onMounted
        // loadSpin.status = false
      })
      .catch(e => {
        openToast(e.message)
        loadSpin.status = false
      })
    }
    function removeImage(idx) {
      console.log(`removing ${idx}`)
      state.images.splice(idx, 1);
    }

    function onFileSelected (e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
          return;
      for (let i = files.length - 1; i >= 0; i--) {
          console.log(files[i].name)
          state.images.push(files[i]);
      }   
      document.getElementById('fileImage').value=[]
    }
    // this can be added into a utility file
    function readFileURL(file) {
      return URL.createObjectURL(file)
    }

    onMounted(() => {
      if ('category' in route.params) {
        const paramcat = route.params.category
        const catinst = cats.find(y => y.val === paramcat)
        state.type = catinst.types[0]
        state.category = paramcat
      }
    })

    onUpdated(() => {
      nextTick( () => {
        loadSpin.status = submitFlag
        console.log('adCreateFirst')
      })
    })

    watch(() => state.type, () => {
        state.category = ""
    })
    return { state, options, submitAd , onFileSelected, 
             readFileURL, addOutline, fileRef,
             fileUpload, router, removeImage,
             closeOutline
             }
  }
})

</script>

<style scoped>
ion-content {
  --color: var(--ion-color-primary-text-dark)
}
h1 {
  color: var(--ion-color-primary-text-dark)
}
.submit-ad {
  --background:  #228F6E !important;
  text-transform: initial;
  letter-spacing: initial;
  --border-radius: 10px;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}

form {
  background-color: white;
  border-radius: 15px;
}

form ion-item {
  font-size: 14px;
  transform: initial;
  margin-bottom: 10px;
  --background: whitesmoke;
  --border-width: 1px;
  --border-color: var(--ion-color-light-shade);
  --border-radius: 15px;
}

form .imglist {
  color: var(--ion-color-primary-text);
  font-size: 14px;
  transform: initial;
  margin-bottom: 10px;
  padding:10px;
  background: whitesmoke;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 15px;    
}
form .imglist img {
  border-radius: 10px;    
}

.option-group ion-label {
   margin-top: 0px;
   margin-bottom: 0px;
}
.option-group ion-item {
  font-size: 12px;
  --background: transparent;
  --background-activated:  hsla(150, 75%, 50%, 1);
  --background-activated-opacity: 1;
  --color-activated: whitesmoke;
}
.option-group ion-item::part(native).item-inner{
  background: red;
}

.option-group .item-radio-checkedx {
  background:  hsla(150, 75%, 50%, 1);
  background: 1;
  color: whitesmoke;
}
.option-group .ion-itemx {
  height: 100%;
  display: block;
  border: 2px solid hsla(150, 75%, 50%, 1);
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
  position: relative;
}

.radio-group input[type=radio] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.radio-group label {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
}

input[type=radio]:checked + label{
  background: var(--ion-color-primary-text);
  color: var(--ion-color-primary-contrast)
}

.radio-item {
  border: 1px solid var(--ion-color-primary-text);
  color: var(--ion-color-primary-text);
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  overflow: hidden;
}
.add-icon {
  border-radius: 10px;
  margin:5px;
  border: 1px solid var(--ion-color-primary-text);
  padding: 15px;
  font-size: 64px;
}
.remove-img {
  --background:whitesmoke;
  background: var(--ion-color-primary-light);
  font-weight: 700;
  border-radius: 15px;
  border: 2px solid var(--ion-color-primary-text);
  position: absolute;
  top:0;
  right:0;
  font-size:24px;
}
.remove-img:hover {
  cursor: pointer;
}
</style>